import React, { Component } from 'react'
import './App.css'
import 'semantic-ui-css/semantic.min.css'
import Airtable from 'airtable'
import { Container, Table } from 'semantic-ui-react'
import {
  XYPlot, 
  XAxis, 
  YAxis, 
  HorizontalGridLines, 
  LineSeries
} from 'react-vis'

const base = new Airtable({ 
  apiKey: '###YOURAPIKEY###' 
}).base('appAYQB4dGYulrRbF');

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      centers: [],
      machines: []
    }
  }
  componentDidMount() {
    base('Work Centers').select({view: 'Grid view'})
      .eachPage(
        (centers) => {
          this.setState({
            centers
          })
        }
      )

    base('Machine Type').select({view: 'Grid view'})
      .eachPage(
        (machines) => {
          this.setState({
            machines
          })
        }
      )
  }
  render() {
    return (
      <Container className="App">
        <XYPlot
          width={300}
          height={300}>
          <HorizontalGridLines />
          <LineSeries
            data={[
              {x: 1, y: 10},
              {x: 2, y: 5},
              {x: 3, y: 15}
            ]}/>
          <XAxis />
          <YAxis />
        </XYPlot>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Center</Table.HeaderCell>
              <Table.HeaderCell>Center Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          <Table.Body>
            {
              this.state.centers.length > 0 ? (
                this.state.centers.map((center, index) =>
                  <Table.Row key={index}>
                    <Table.Cell>{center.fields['ID']}</Table.Cell>
                    <Table.Cell>{center.fields['Center ID']}</Table.Cell>
                    <Table.Cell>{center.fields['Center Name']}</Table.Cell>
                  </Table.Row>
                )
              ) : (
                <p>Loading...</p>
              )
            }
          </Table.Body>
        </Table>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Materials</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          <Table.Body>
            {
              this.state.machines.length > 0 ? (
                this.state.machines.map((machine, index) =>
                  <Table.Row key={index}>
                    <Table.Cell>{machine.fields['Name']}</Table.Cell>
                    <Table.Cell>{machine.fields['Materials']}</Table.Cell>
                  </Table.Row>
                )
              ) : (
                <p>Loading...</p>
              )
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default App